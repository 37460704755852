import { CloseButton } from '@mybridge/ui/close-button';
import { Image } from '@mybridge/ui/image';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { MediaSlider, MediaSliderItem } from '@mybridge/ui/media-slider';
import { useContext, useEffect, useState } from 'react';
import { fileToDataURL } from 'v4/lib/file';
import { PostFormContext } from '../context';
import { Box, Stack } from '@mybridge/ui/layout';

export const AvailableEditableMediaContainer = ({images, videos, gifs, itemIndex}) => {
  const { multiPost, setMultiPost } = useContext(PostFormContext) ?? {};
  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);

  useEffect(() => {
    prepareImages();
  }, [images]);

  useEffect(() => {
    prepareVideos();
  }, [videos]);

  useEffect(() => {
    prepareGifs();
  }, [gifs]);

  const prepareVideos = async () => {
    const vids = [];
    if(videos){
      for (let vid of videos) {
        if(typeof(vid) === 'string'){
          vid.toLowerCase().includes("mp4") && vids.push(vid);
        }else if(typeof(vid.file)==='string' && vid.type !== "IMAGE"){
          vids.push(vid.file);
        }else{
          const url = await fileToDataURL(vid);
        }
      }
      setVideos_(vids);
    }
  };

  const prepareImages = async () => {
    const imgs = [];
    if(images){
      for (let img of images) {
        imgs.push(typeof img === 'string' ? img : typeof img?.file === 'string' ? img?.file : await fileToDataURL(img?.file || img));
      }
      setImages_(imgs);
    }
  };

  const prepareGifs = async () => {
    const gs = [];
    if(gifs){
      for (let gif of gifs) {
        gs.push(gif?.images?.original?.url || gif);
      }
      setGifs_(gs);
    }
  };


  const handleRemoveImage = (index) => {
    const newMultiPost = multiPost.map((item, i) => {
      if (i === index) {
          return { ...item, media: [] };
      }
      return item;
    });
    setMultiPost(newMultiPost);
  };

  const handleRemoveVideo = (index) => {
    const newMultiPost = multiPost.map((item, i) => {
      if (i === index) {
          return { ...item, media: [] };
      }
      return item;
    });
    setMultiPost(newMultiPost);
  };


  const handleRemoveGif = (index) => {
    const newMultiPost = multiPost.map((item, i) => {
      if (i === index) {
          return { ...item, gif: null };
      }
      return item;
    });
    setMultiPost(newMultiPost);
  };

  return (
    <>
      {videos_?.length ? (
        <Box pos="relative" borderRadius="5" overflow="hidden">
          <CloseButton
            variant="round"
            zIndex={3}
            onClick={() => handleRemoveVideo(itemIndex)}
            bg="white"
            pos="absolute"
            top={2}
            right={2}
          />
          <VideoPlayer src={videos_?.[0]} />
        </Box>
      ) : (
        <>
          {images_?.length ? (
            <MediaSlider>
              {images_?.map?.((img, index) => (
                <MediaSliderItem
                  key={index}
                  minW="200px"
                  maxH="200px"
                  bg="blackAlpha.800"
                >
                  <CloseButton
                    variant="round"
                    onClick={() => handleRemoveImage(itemIndex)}
                    bg="white"
                    pos="absolute"
                    top={2}
                    right={2}
                  />
                  <Image maxH="300px" src={img} alt={"img"}/>
                </MediaSliderItem>
              ))}
            </MediaSlider>
          ) : (
            <>
              {gifs_?.length ? (
                // <Box maxH="200px" overflowY="auto" borderRadius="5">
                <Stack bg="blackAlpha.900" alignItems="center" pos="relative">
                  <CloseButton
                    variant="round"
                    onClick={() => handleRemoveGif(itemIndex)}
                    bg="white"
                    pos="absolute"
                    top={2}
                    right={2}
                    cursor="pointer"
                  />
                  <Image maxH={'300px'} src={gifs_?.[0]} />
                  {/* </Box> */}
                </Stack>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
